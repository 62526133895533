export const customerData = [
    {
        id: 1,
        title: 'Wall & Tabletop Decor',
        products: [
            {
                id: 11,
                subtitle: 'PHOTO FRAME',
                pics: [
                    {
                        name: 'Product Name',
                        picLink:
                            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/Wall_Tabletop_Decor_-_Photo_Frame-2_qksxkf.webp',
                    },
                    {
                        name: 'Product Name',
                        picLink:
                            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/Wall_Tabletop_Decor_-_Photo_Frame-4_p0jtly.webp',
                    },
                    {
                        name: 'Product Name',
                        picLink:
                            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/Wall_Tabletop_Decor_-_Photo_Frame-3_nvpcei.webp',
                    },
                    {
                        name: 'Product Name',
                        picLink:
                            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/Wall_Tabletop_Decor_-_Photo_Frame-1_y3yymd.webp',
                    },
                ],
            },
            {
                id: 12,
                subtitle: 'FRAMED ART',
                pics: [
                    {
                        name: 'Product Name',
                        picLink:
                            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/Wall_Tabletop_Decor_-_Framed_Art-1_u8cxax.webp',
                    },
                    {
                        name: 'Product Name',
                        picLink:
                            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/Wall_Tabletop_Decor_-_Framed_Art-2_a6pvjw.webp',
                    },
                    {
                        name: 'Product Name',
                        picLink:
                            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/Wall_Tabletop_Decor_-_Framed_Art-4_jxx5lj.webp',
                    },
                    {
                        name: 'Product Name',
                        picLink:
                            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/Wall_Tabletop_Decor_-_Framed_Art-3_c2aakg.webp',
                    },
                ],
            },
            {
                id: 13,
                subtitle: 'MIRRORS',
                pics: [
                    {
                        name: 'Product Name',
                        picLink:
                            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/pexels-camille-robinson-7356405_wgeoqd.webp',
                    },
                    {
                        name: 'Product Name',
                        picLink:
                            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/pexels-luis-ruiz-4022430_p6m0he.webp',
                    },
                    {
                        name: 'Product Name',
                        picLink:
                            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/pexels-rachel-claire-5490357_ylg3ju.webp',
                    },
                ],
            },
            {
                id: 14,
                subtitle: 'WALL LEDGES',
                pics: [
                    {
                        name: 'Product Name',
                        picLink:
                            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/pexels-cottonbro-4503943_mvwmmx.webp',
                    },
                    {
                        name: 'Product Name',
                        picLink:
                            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/MZ900103-5_svve7c.webp',
                    },
                    {
                        name: 'Product Name',
                        picLink:
                            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/MZ900102-4_kw2bxv.webp',
                    },
                    {
                        name: 'Product Name',
                        picLink:
                            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/MZ900101-4_yv8vim.webp',
                    },
                ],
            },
            {
                id: 15,
                subtitle: 'CLOCK',
                pics: [
                    {
                        name: 'Product Name',
                        picLink:
                            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/pexels-ekaterina-bolovtsova-4050215_cwc6aj.webp',
                    },
                    {
                        name: 'Product Name',
                        picLink:
                            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/pexels-annpoan-5849392_avxtd9.webp',
                    },
                    {
                        name: 'Product Name',
                        picLink:
                            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/pexels-annpoan-5799379_sj834o.webp',
                    },
                ],
            },
        ],
    },
    {
        id: 2,
        title: 'Multi-Functional',
        products: [
            {
                id: 21,
                subtitle: 'OFFICE FURNITURE',
                pics: [
                    {
                        name: 'Product Name',
                        picLink:
                            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/017A0369_lb5p33.webp',
                    },
                    {
                        name: 'Product Name',
                        picLink:
                            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/DSC_8153_keaabk.webp',
                    },
                    {
                        name: 'Product Name',
                        picLink:
                            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/2_gqw71d.webp',
                    },
                ],
            },
            {
                id: 22,
                subtitle: 'KITCHEN CABINET',
                pics: [
                    {
                        name: 'Product Name',
                        picLink:
                            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/asr-design-studio-tyeu_Wnb-9g-unsplash_ccrvdc.webp',
                    },
                    {
                        name: 'Product Name',
                        picLink:
                            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/christian-mackie-cc0Gg3BegjE-unsplash_1_bhh2ad.webp',
                    },
                    {
                        name: 'Product Name',
                        picLink:
                            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/pexels-curtis-adams-1694007-15062126_lyrufe.webp',
                    },
                ],
            },
            {
                id: 23,
                subtitle: 'BATCHROOM VANITY',
                pics: [
                    {
                        name: 'Product Name',
                        picLink:
                            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/pexels-curtis-adams-1694007-19227244_tq41nk.webp',
                    },
                    {
                        name: 'Product Name',
                        picLink:
                            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/pexels-curtis-adams-1694007-19227243_nlzj7x.webp',
                    },
                    {
                        name: 'Product Name',
                        picLink:
                            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/pexels-christa-grover-1910472_1_fqbnp1.webp',
                    },
                ],
            },
        ],
    },
    {
        id: 3,
        title: 'Storage Furniture',
        products: [
            {
                id: 31,
                subtitle: 'CABINET DRAWER BOX',
                pics: [
                    {
                        name: 'Product Name',
                        picLink:
                            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/Dovetail-Drawer-Boxes_savnn8.webp',
                    },
                    {
                        name: 'Product Name',
                        picLink:
                            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/Drawerbox-Rollout_xm5hql.webp',
                    },
                    {
                        name: 'Product Name',
                        picLink:
                            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/White-Birch-Fingerjointed_bgtoyf.webp',
                    },
                ],
            },
            {
                id: 32,
                subtitle: 'CABINET SMART ACCESSORIES',
                pics: [
                    {
                        name: 'Product Name',
                        picLink:
                            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/apic-00553264_buzbwa.webp',
                    },
                    {
                        name: 'Product Name',
                        picLink:
                            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/2017_Base-Magic-Corner-Storage-Unit_alo46s.webp',
                    },
                ],
            },
            {
                id: 33,
                subtitle: 'CLOSET STORAGE SYSTEM',
                pics: [
                    {
                        name: 'Product Name',
                        picLink:
                            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/pexels-hakimsatoso-9646744_zpapnb.webp',
                    },
                    {
                        name: 'Product Name',
                        picLink:
                            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/pexels-luis-kuthe-3099301-5033522_iu4zo5.webp',
                    },
                ],
            },
        ],
    },
]
