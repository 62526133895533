import React, { useCallback, useState } from 'react'
import NavB from './Nav'
import SideDrawer from '../sideDrawer/SideDrawer'
import Backdrop from '../backDrop/Backdrop'

const Header = () => {
    const [sideDrawerOpen, setSideDrawerOpen] = useState(false)

    const drawerToggleClickHandler = useCallback(() => {
        setSideDrawerOpen(!sideDrawerOpen)
    }, [sideDrawerOpen])

    const backdropClickHandler = useCallback(() => {
        setSideDrawerOpen(false)
    }, [])

    return (
        <>
            <NavB drawerClickHandler={drawerToggleClickHandler} />
            <SideDrawer show={sideDrawerOpen} click={backdropClickHandler} />
            {sideDrawerOpen && <Backdrop click={backdropClickHandler} />}
        </>
    )
}

export default Header
