import React from 'react'
import { aboutUsData, picList } from '../config/aboutData'

const AboutDetailItem = ({ item }) => {
    if (item?.picLink) {
        return (
            <div className="item-with-pic-container">
                <div className="item-text">
                    {item?.subtitle && <h4>{item?.subtitle}</h4>}
                    <div className="detail-item-content">{item.content}</div>
                </div>
                <div className="item-pic">
                    <img
                        src={item.picLink}
                        alt="item pic"
                        className="item-pic-conifg"
                    />
                </div>
            </div>
        )
    }
    return (
        <div>
            {item?.subtitle && <h4>{item?.subtitle}</h4>}
            <div className="detail-item-content">{item.content}</div>
        </div>
    )
}

const AboutUsTopItems = ({ data }) => {
    return (
        <div>
            <h3 className="about-top-item-title">{data.title}</h3>
            {data.details.map((item) => (
                <AboutDetailItem item={item} key={item.id} />
            ))}
        </div>
    )
}

const About = () => {
    return (
        <div className="home-container">
            <section className="section-container">
                <div className="about-header">
                    <h1 className="about-header-font">About Us</h1>
                </div>
                <div className="about-top-container">
                    {aboutUsData.map((item) => (
                        <AboutUsTopItems data={item} key={item.id} />
                    ))}
                </div>
                <div className="about-pic-list-container">
                    {picList.map((pic, index) => (
                        <img
                            src={pic}
                            alt="pic list"
                            key={index}
                            className="pic-list-pic"
                        />
                    ))}
                </div>
                <div className="about-header">
                    <h1 className="about-header-font">
                        Aldima History & Milestones
                    </h1>
                </div>
                <div className="bottom-header">
                    <div className="bottom-item">
                        <h2 className="bottom-title">
                            TO SET THE GLOBAL STANDARD IN HOME DECOR WITH
                            INNOVATIVE DESIGNS, UNMATCHED QUALITY, AND
                            SUSTAINABLE PRACTICES
                        </h2>
                    </div>
                    <div className="bottom-item">
                        <img
                            src="https://res.cloudinary.com/djd56oewd/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1729705764/About_US_Page_-_History_cwxflj.webp"
                            alt="header pic"
                            className="header-pic"
                        />
                    </div>
                </div>
                <div className="bottom-content">
                    Founded in 1993, we began by partnering with IKEA and
                    expanded into photo and art frame production in 2000. In
                    2007, we invested in the Tianjin Shoutai factory, followed
                    by a new Cingdao facility in 2011. By 2013, we added
                    in-house mirror glass plating and mass production in
                    Shousheng. Kitchen cabinet production started in 2018, with
                    further expansion into Thailand in 2019 and 2022. In 2024,
                    we launched mass production in our new Thai plant,
                    strengthening our global reach.
                </div>
            </section>
        </div>
    )
}

export default About
