export const missionData = [
    {
        id: 1,
        title: 'Mission',
        content:
            'We elevate living spaces with elegant, high-quality decor, blending innovation and craftsmanship to create timeless designs for every home.',
    },
    {
        id: 2,
        title: 'Vision',
        content:
            'To be a global leader in home decor, known for trendsetting designs, exceptional quality, and sustainable practices.',
    },
    {
        id: 3,
        title: 'Values',
        content:
            'We value quality, innovation, customer satisfaction, integrity, and sustainability in every aspect of our business.',
    },
]

export const ourProductList = [
    {
        id: 1,
        title: 'Wall Decor',
        picLink:
            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/Home_Page_-_Wall_Decor_h0lmun.webp',
    },
    {
        id: 2,
        title: 'Table Decor',
        picLink:
            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/Home_Page_-_Table_Decor_vwzmju.webp',
    },
    {
        id: 3,
        title: 'Multi-Functional',
        picLink:
            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/MZ900102-5_vjcxkf.webp',
    },
    {
        id: 4,
        title: 'Storage Furniture',
        picLink:
            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_300,h_300/Home_Page_-_Storage_Furniture_fgfcgy.webp',
    },
]

export const homeCarouselPics = [
    {
        id: 1,
        picLink:
            'https://res.cloudinary.com/djd56oewd/image/upload/c_fill,w_500,h_500/Aldima%20Global%20LLC./Home%20Page/New%20Footer%20Image.webp',
    },
    {
        id: 2,
        picLink:
            'https://res.cloudinary.com/djd56oewd/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1731345021/Aldima%20Global%20LLC./Home%20Page/Homepage%20background.webp',
    },
    {
        id: 3,
        picLink:
            'https://res.cloudinary.com/djd56oewd/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1729777556/MZ900102-5_vjcxkf.webp',
    },
    {
        id: 4,
        picLink:
            'https://res.cloudinary.com/djd56oewd/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1729776995/pexels-orlovamaria-4906250_i3tx95.webp',
    },
    {
        id: 5,
        picLink:
            'https://res.cloudinary.com/djd56oewd/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1729705727/Home_Page_-_Table_Decor_vwzmju.webp',
    },
    {
        id: 6,
        picLink:
            'https://res.cloudinary.com/djd56oewd/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1729705724/Home_Page_-_Wall_Decor_h0lmun.webp',
    },
]
