import React from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import {
    homeCarouselPics,
    missionData,
    ourProductList,
} from '../config/homeData'
import { Link, useNavigate } from 'react-router-dom'

const VisionPart = ({ data }) => {
    return (
        <div className="mission-part-item">
            <div className="mission-part-item-title">{data.title}</div>
            <div>{data.content}</div>
        </div>
    )
}

const ProductsPart = ({ data }) => {
    const navigate = useNavigate()
    return (
        <div
            className="product-part-item-container"
            onClick={() => navigate('/products')}
        >
            <div className="product-part-item">
                <img
                    src={data.picLink}
                    alt="product pic"
                    className="product-part-item-pic"
                />
            </div>
            <div className="product-part-item-title">{data.title}</div>
        </div>
    )
}

const CarouselPics = () => {
    return (
        <Carousel
            autoPlay={true}
            centerMode={true}
            dynamicHeight={true}
            infiniteLoop={true}
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            centerSlidePercentage={50}
            className="custom-carousel"
        >
            {homeCarouselPics.map((picture) => (
                <div key={picture.id}>
                    <img src={picture.picLink} alt="carousePic" />
                </div>
            ))}
        </Carousel>
    )
}

const Home = () => {
    return (
        <div className="home-container">
            <section className="section-container">
                <div className="mission-container">
                    {missionData.map((item) => (
                        <VisionPart data={item} key={item.id} />
                    ))}
                </div>
                <div className="our-products-title">
                    <Link to={'/products'}>
                        <h3 className="our-products-title-font">
                            OUR PRODUCTS
                        </h3>
                    </Link>
                </div>
                <div className="products-container">
                    {ourProductList.map((item) => (
                        <ProductsPart data={item} key={item.id} />
                    ))}
                </div>
                <div className="home-carousel-container">
                    <CarouselPics />
                </div>
            </section>
        </div>
    )
}

export default Home
