import React from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import { customerData } from '../config/customerData'

const CarouselPics = ({ picList }) => {
    return (
        <Carousel
            autoPlay={false}
            centerMode={true}
            dynamicHeight={true}
            infiniteLoop={true}
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            centerSlidePercentage={100}
            className="custom-carousel"
        >
            {picList?.map((picture, index) => (
                <div key={index}>
                    <img src={picture.picLink} alt="carousePic" />
                </div>
            ))}
        </Carousel>
    )
}
const ProductItem = ({ product }) => {
    return (
        <div className="product-item-container">
            <div className="product-item-title">-- {product.subtitle} --</div>
            <div className="product-item-carousel">
                <CarouselPics picList={product.pics} />
            </div>
        </div>
    )
}
const Product = ({ products }) => {
    return (
        <div className="product-container">
            <h2 className="product-title">{products?.title}</h2>
            <div className="product-items">
                {products?.products?.map((product) => (
                    <ProductItem key={product.id} product={product} />
                ))}
            </div>
        </div>
    )
}

const Customers = () => {
    return (
        <div className="home-container">
            <section className="section-container">
                {customerData?.map((item) => (
                    <Product key={item.id} products={item} />
                ))}
            </section>
        </div>
    )
}

export default Customers
