import React from 'react'

const Footer = () => {
    const currentYear = new Date().getFullYear()
    return (
        <div className="footer-container">
            <div className="footer-box">
                <div className="footer-content">
                    © {currentYear} Aldima Global Gifts & Accessories
                </div>
            </div>
        </div>
    )
}

export default Footer
