import React from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import emailjs from '@emailjs/browser'

const getInTouchSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    phone: Yup.string().min(6).max(12).required('Required'),
    message: Yup.string().max(300),
})

const Contact = () => {
    return (
        <div className="home-container">
            <section className="section-container">
                <div className="contact-container">
                    <h1 className="contact-header">Contact Us</h1>
                    <div className="contact-details">
                        Aldima Global is dedicated to providing our customers
                        with the highest quality home décor products at
                        affordable prices, offering a wide range of categories
                        to meet the needs of most home goods retailers. Each
                        season, our team introduces new designs that align with
                        the latest trends and closely reflect our clients' brand
                        styles. We offer comprehensive logistics support,
                        including EDI, ASN, and invoicing, ensuring a smooth and
                        efficient process.
                    </div>
                    <Formik
                        initialValues={{
                            name: '',
                            email: '',
                            phone: '',
                            message: '',
                        }}
                        validationSchema={getInTouchSchema}
                        onSubmit={(values, { resetForm }) => {
                            emailjs
                                .send(
                                    'service_zl555at',
                                    'template_wgdek25',
                                    values,
                                    'nz2bY5jm9LzfRm7T1'
                                )
                                .then(
                                    (result) => {
                                        console.log(result.text)
                                    },
                                    (error) => {
                                        console.log(error)
                                    }
                                )
                            window.alert('Thanks for your information')
                            resetForm()
                        }}
                    >
                        {({ errors, touched, isValid }) => (
                            <Form>
                                <div className="touch-input-box">
                                    <div className="touch-input-item">
                                        <Field
                                            name="name"
                                            className="touch-input-style"
                                            placeholder="NAME"
                                        />
                                        <Field
                                            name="email"
                                            className="touch-input-style"
                                            placeholder="EMAIL"
                                        />
                                        <Field
                                            name="phone"
                                            className="touch-input-style"
                                            placeholder="PHONE"
                                        />
                                    </div>
                                    <div className="touch-input-item">
                                        <Field
                                            name="message"
                                            as="textarea"
                                            className="touch-textarea-style"
                                            placeholder="MESSAGE"
                                        />
                                        <button
                                            type="submit"
                                            className="touch-button"
                                            disabled={!isValid}
                                        >
                                            SUBMIT
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </section>
        </div>
    )
}

export default Contact
