export const aboutUsData = [
    {
        id: 1,
        title: '1- Extensive Manufacturing Capacity:',
        details: [
            {
                id: 11,
                subtitle: 'Factory in Thailand',
                content:
                    'Our factory spans 1,653,336 sq. ft., with the Ist phase (cabinet production) covering 440,286 sq. ft. and the 2nd phase (flat furniture and wrapped products) covering 604,479 sq. ft. We ship 80-100 containers monthly to various countries, ensuring a global reach.',
                picLink:
                    'https://res.cloudinary.com/djd56oewd/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1729705763/WPS_Photos_1_lm8tbe.webp',
            },
            {
                id: 12,
                subtitle: 'Facroty in China',
                content:
                    'Founded in 1993, we began by partnering with IKEA and expanded into photo and art frame production in 2000. In 2007, we invested in the Tianjin Shoutai factory, followed by a new Qingdao facility in 2011. By 2013, we added in-house mirror glass plating and mass production in Shousheng. Kitchen cabinet production started in 2018. Our factorty has 1,937,503.875 sq.ft. building area.',
                picLink:
                    'https://res.cloudinary.com/djd56oewd/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1729705766/6_rapbmd.webp',
            },
        ],
    },
    {
        id: 2,
        title: '2- Expert Design Team:',
        details: [
            {
                id: 21,
                content:
                    "Our professional design team conducts market research to forecast and innovate best-selling products for each upcoming season. Our designs not only align with current trends but also meet the specific requirements of our partner brands, ensuring seamless integration with each brand's identity.",
                picLink:
                    'https://res.cloudinary.com/djd56oewd/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1729705767/c53bcc3df559a156eef047d8f379a83_d269zc.webp',
            },
        ],
    },
    {
        id: 3,
        title: '3- Material Expertise:',
        details: [
            {
                id: 31,
                content:
                    'We excel in producing wood-based products and are actively expanding into new materials, continually innovating to meet diverse market needs.',
            },
        ],
    },
    {
        id: 4,
        title: '3- Robust Logistics Services:',
        details: [
            {
                id: 41,
                content:
                    'We offer comprehensive logistics support, including EDI, ASN, and invoicing, ensuring a smooth and efficient process. For U.S. collaborations, we have a dedicated warehouse in Dallas.',
            },
        ],
    },
]

export const picList = [
    'https://res.cloudinary.com/djd56oewd/image/upload/v1729705760/WPS_Photos_1_vlwgg1.webp',
    'https://res.cloudinary.com/djd56oewd/image/upload/v1729705759/WPS_Photos_2_aretor.webp',
    'https://res.cloudinary.com/djd56oewd/image/upload/v1729705761/WPS_Photos_5_oc4ee9.webp',
    'https://res.cloudinary.com/djd56oewd/image/upload/v1729705757/WPS_Photos_3_h2o76h.webp',
    'https://res.cloudinary.com/djd56oewd/image/upload/v1729705756/WPS_Photos_4_ygd0tp.webp',
]
