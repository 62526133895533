import React from 'react'
import { headerData } from '../config/headerData'
import { Link } from 'react-router-dom'

const SideDrawer = (props) => {
    let drawerClasses = ['side-drawer']
    if (props.show) drawerClasses = ['side-drawer open']
    return (
        <nav className={drawerClasses}>
            <ul>
                {headerData.map((item, index) => (
                    <Link to={item.location} key={index}>
                        <li key={item.id} onClick={props.click}>
                            {item.title}
                        </li>
                    </Link>
                ))}
            </ul>
        </nav>
    )
}

export default SideDrawer
