import React from 'react'
import { headerData } from '../config/headerData'

import DrawerToggle from '../sideDrawer/DrawerToggle'
import { Link } from 'react-router-dom'

const NavB = ({ drawerClickHandler }) => {
    window.addEventListener('scroll', function () {
        const header = document.querySelector('header')
        header.classList.toggle('active', window.scrollY > 0)
    })
    return (
        <header className="toolbar">
            <div className="toolbar-container">
                <nav className="toolbar__navigation">
                    <div className="toolbar__logo">
                        <img
                            src="https://res.cloudinary.com/djd56oewd/image/upload/v1731728933/Logo-PNG_vfalig_c_crop_w_2100_h_700_mdud8r.webp"
                            alt="logo"
                        />
                    </div>
                    <div className="toolbar__toggle-button">
                        <DrawerToggle click={drawerClickHandler} />
                    </div>
                    <div className="toolbar_navigation-items">
                        <ul>
                            {headerData.map((item, index) => (
                                <Link to={item.location} key={index}>
                                    <li key={item.id}>{item.title}</li>
                                </Link>
                            ))}
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    )
}

export default NavB
